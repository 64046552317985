import React from 'react';

import styles from './home.module.css';

export default () => (
	<main className={styles.root}>
		<p>nothing to see here.</p>
		<p>sometimes i <a href="https://twitter.com/rayhatfield">tweet</a>.</p>
	</main>
);
